@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .sv-question__title--answer {
        @apply bg-violet-400 font-bold text-coolGray-600
    }
  }
